<template>
	<div class="typingRecord">
		<div class="filter-container">
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input placeholder="推广名称" v-model="keyWord" style="width: 250px;"></el-input> 
			</div>
			<div class="filter-item">
				<el-button type='primary' style="width:90px;" @click="filterFun">查询</el-button>
				<el-button type='primary' style="width:90px;" @click="handleEdit({},1)">添加推广</el-button>
			</div> 
		</div>

		<!-- 表格 -->
		<!-- :row-key="rowKey" -->
		<el-table :data="groupData"  style="width: 100%;" v-loading="loading" ref="multipleTable">
			<el-table-column prop="Name" label="推广名称"></el-table-column>
			<el-table-column prop="MallShopDecorateName" label="链接页面"></el-table-column>
			<el-table-column prop="AddTime" label="创建时间"></el-table-column>
			<el-table-column label="操作" width="200">
				<template slot-scope="scope">
					<el-button type="text" @click="handleEdit(scope.row,2)">编辑</el-button>
					<el-button type="text" @click="handlePromote(scope.row)">推广</el-button>
					<el-button type="text" @click="handleShowData(scope.row)">数据</el-button>
					<el-button type="text" @click="handleDelete(scope.row.Id)" style="color:#F56C6C">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div style="text-align: right;">
			<el-pagination v-if="page.total" style="margin:20px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="page.size" layout="total, sizes, prev, pager, next, jumper"
			 :total="page.total">
			</el-pagination>
		</div>

    <el-dialog
      :title="`${editType=='1'?'添加':'编辑'}渠道推广码`"
      :visible.sync="editPromoteVisible"
      width="800px">
      <el-form :model="ruleEditForm" :rules="rulesEdit" ref="ruleEditForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="推广名称：" prop="Name">
          <el-input v-model="ruleEditForm.Name" maxLength='20' style="width: 450px;"></el-input>
        </el-form-item>
        <el-form-item label="链接页面：" prop="LinkName">
					<span class="link-name-btn" v-if='editType==1&&ruleEditForm.LinkName==""' @click="handleSelectPage()">请选择页面</span>
          <el-button v-if='editType==1&&!ruleEditForm.LinkName==""' type="text" @click="handleSelectPage()">{{ruleEditForm.LinkName}}</el-button>
          <span v-else style='color:#409EFF;'>{{ruleEditForm.LinkName}}</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editPromoteVisible = false">关闭</el-button>
        <el-button type="primary" v-loading='saveLoading' @click="editPromoteSure('ruleEditForm')">保存</el-button>
      </div>
    </el-dialog>

		<el-dialog
      title="选择页面"
      :visible.sync="selectPageVisible"
      width="800px">
			<el-tabs v-model="PromoteCodeType" type="card" @tab-click="handleTabClick">

				<el-tab-pane label="系统页面"  name="1">
					<el-radio style='line-height:50px;margin-left:20px;' v-model="selectedRadio" :label="true">商城首页</el-radio>
				</el-tab-pane>

				<el-tab-pane label="自定义页面" name="2">
					<!-- table -->
					<div class="table-container content">
						<el-table :data="pageDataList" height='500px' style="width: 100%" ref="compSelectProTable" v-loading='pageLoading'
						:row-key="getRowKeys">
							<el-table-column width="55">
								<template scope="scope">
									<el-radio :label="scope.row.MallShopDecorateId" v-model="templateRadio"
									@change.native="getTemplateRow(scope.row)">{{&nbsp;}}</el-radio>
								</template>
							</el-table-column>
							<el-table-column prop="PageName" label="页面名称"></el-table-column>
							<el-table-column prop="AddTime" label="创建时间"></el-table-column>
						</el-table>

						<el-pagination v-if="selectPage.total" style="margin-top:20px;float:right;" @size-change="handleSelectSizeChange"
						@current-change="handleSelectCurrentChange" :current-page="selectPage.current" :page-sizes="[5,10, 20, 30, 40, 50]" :page-size="selectPage.size"
						layout="total, sizes, prev, pager, next, jumper" :total="selectPage.total">
						</el-pagination>
					</div>
				</el-tab-pane>
			</el-tabs>
			<div slot="footer" class="dialog-footer">
				<el-button class="button" @click="saveSelection" style="margin-left:10px;" type="primary">确定</el-button>
			</div>
    </el-dialog>

		<!-- 推广 -->
		<el-dialog title="渠道推广码" :visible.sync="codeVisible" width="500px">
		<div v-loading="codeding">
			<div class = "flexRow" style="margin-top:20px">
				<div class = "rightfont">短信链接：</div>
				<el-input v-model="smslink" style="flex:1" disabled></el-input>
				<el-button v-clipboard:copy="smslink" v-clipboard:success="onCopy" v-clipboard:error="onError">
					复制
				</el-button>
			</div>
			<img :src="imgUrl+QrCodePage" alt="" id="codeId" style="width:300px;display: block;margin: 30px auto 0">
			<div style="text-align:center;margin-top: 25px;">
				<span style="color:#409EFF;cursor:pointer;padding:20px 0;display: inline-block; " @click="downloadIamge('img',QrCodeName)">下载渠道推广码</span>
			</div>
		</div>
	</el-dialog>
	</div>
</template>

<script>
	import config from '@/config/index'
	import apiList from '@/api/other'
	import {
		wxappwxappgenerateurllink
	} from "@/api/sv3.0.0"
	export default {
		data() {
			return {
				keyWord: '',
				groupData: [{
					id: 0
        }],
        page:{
          size:20,
          current:1,
          total:0
        },
				loading: false,
        exportUrl: config.EXPORT_URL,
				imgUrl: config.IMG_BASE,
        editPromoteVisible:false,
        editType:1,
        promoteSureLoading:false,
        ruleEditForm:{
					Id:0,
          Name:'',
					LinkName:'',
					PromoteCodeType:'1',
					MallShopDecorateId:null
        },
        rulesEdit:{
          Name: [{
						required: true,
						trigger: 'blur',
						message: '请输入推广名称',
					}],
					LinkName: [{
						trigger: 'blur',
						required: true,
						message: '请选择链接页面',
					}],
				},
				PromoteCodeType:'1',
				selectPageVisible:false,
				selectedRadio: false,
				templateRadio: 0,
				selectPage:{
          size:10,
          current:1,
          total:0
				},
				pageDataList:[],
				pageLoading:false,
				selection:[],
				saveLoading: false,
				QrCodePage:'',
				QrCodeName:'',
				codeding:false,
				codeVisible:false,

				smslink:'',
			}
		},
		beforeMount() {
			this.getList()
			this.getPageList()
		},
		methods: {
			onCopy() {
				this.$message({
					message: "复制成功！",
					type: 'success'
				});

			},
			onError() {
				this.$message({
					message: "复制失败！",
					type: 'error'
				});
			},
			// 获取列表数据
			async getList() {
				try {
					this.loading = true
					let data = {
						KeyWrod : this.keyWord,
						Skip: (this.page.current - 1) * this.page.size, // 略过数据
						Take: this.page.size, // 取的数据
					}
					let result = await apiList.promoteCodeList(data);
					this.page.total = result.Result.Total;
					this.groupData = result.Result.Results|| [];

				} catch (e) {
					console.log(e)
				} finally {
					this.loading = false
				}

			},
			filterFun() {
				this.page.current = 1
				this.getList()
      },
      // 编辑
			handleEdit(val,type){
				if(type==1){
					this.ruleEditForm = {
						Id:0,
						Name:'',
						LinkName:'',
						PromoteCodeType:'1',
						MallShopDecorateId:null
					}
					this.PromoteCodeType = '1'
				}else{
					this.ruleEditForm = {
						Id:val.Id,
						Name:val.Name,
						LinkName:val.MallShopDecorateName,
						PromoteCodeType:String(val.PromoteCodeType),
						MallShopDecorateId:val.MallShopDecorateId
					}
					this.PromoteCodeType = val.PromoteCodeType
				}
        this.editType = type
				this.editPromoteVisible = true
				this.$nextTick(()=>{
					this.$refs.ruleEditForm.clearValidate()
				})
      },
      // 编辑保存
      editPromoteSure(formName){
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.saveLoading = true 
						try{
							let result = await apiList.promoteCodeSave(this.ruleEditForm)
							if(result.IsSuccess){
								this.$message({
									showClose:true,
									type:'success',
									message:'保存成功'
								})
							}
						} catch(e){

						} finally{
							this.saveLoading = false 
							this.editPromoteVisible = false
							this.getList()
						}
					}
				})
      },
			// 推广
			async handlePromote(val){
				this.codeVisible = true
					try {
						this.codeding=true
						let data = {
							Page: val.QrCodePage,
							Scene: val.QrCodeScene,
							IsLimited: false,
							ProgramType: 0,
						}
						let result = await apiList.mallGetQrCode(data)
						this.QrCodePage = JSON.parse(result.Result)[0]
						this.QrCodeName = val.Name

						let msg = {
							Path:val.QrCodePage,
							query:val.QrCodeScene
						}
						let res = await wxappwxappgenerateurllink(msg)
						if(res.IsSuccess){
							this.smslink = res.Result.url_link
						}
					} catch (e) {
						console.log('e', e)
					}finally{
						this.codeding=false
					}
			},
			// 下载
			downloadIamge(selector, name) {
				var image = new Image()
				image.setAttribute('crossOrigin', 'anonymous')
				image.onload = function() {
					var canvas = document.createElement('canvas')
					canvas.width = image.width
					canvas.height = image.height

					var context = canvas.getContext('2d')
					context.drawImage(image, 0, 0, image.width, image.height)
					var url = canvas.toDataURL('image/png')

					var a = document.createElement('a')
					var event = new MouseEvent('click')

					a.download = name || '下载图片名称'
					a.href = url
					a.dispatchEvent(event)
				}
				image.src = document.getElementById('codeId').src
			},
			// 数据
			handleShowData(record){
				this.$router.push({
					path: '/market/channelStatistics',
					query: {
						id: record.Id,
						Name:record.Name,
						MallShopDecorateName:record.MallShopDecorateName
					}
				})
			},
			// 删除
      handleDelete(Id){
				this.$confirm('删除成功后，不可恢复，是否确认继续删除？', {
          confirmButtonText: '继续删除',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
					try{
						let result = await apiList.promoteCodeDelete({
							Id:Id
						})
						if(result.IsSuccess){
							this.$message({
								showClose:true,
								type:'success',
								message:'删除成功'
							})
						}
					} catch(e){

					} finally{
						this.getList()
					}
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
			},
      // 选择页面
			handleSelectPage(){
				this.selectPageVisible = true
				this.selection = {}
				this.templateRadio = this.ruleEditForm.MallShopDecorateId
				this.selectedRadio =this.ruleEditForm.LinkName==''?false:(this.ruleEditForm.PromoteCodeType==1?true:false)
				this.selection = {
					MallShopDecorateId: this.ruleEditForm.MallShopDecorateId,
					PageName: this.ruleEditForm.LinkName,
				}
			},
			// 切换tab
			handleTabClick(){
				this.templateRadio = 0
				this.selection = {}
				this.selectedRadio = false
			},

			// 获取page列表
			async getPageList(){
				let result = await apiList.mallShopDecorateBoxList({
					Skip: (this.selectPage.current - 1) * this.selectPage.size, // 略过数据
					Take: this.selectPage.size, // 取的数据
				})
				this.selectPage.total = result.Result.Total;
				this.pageDataList  = result.Result.Results
			},
			getRowKeys(row) {
				return row.MallShopDecorateId;
			},
			//单选按钮
			getTemplateRow(row) {
				this.selection = row
			},

			//确定选择
			saveSelection() {
				if(this.PromoteCodeType==1&&this.selectedRadio){
					this.ruleEditForm.MallShopDecorateId  = 0
					this.ruleEditForm.PromoteCodeType = this.PromoteCodeType
					this.ruleEditForm.LinkName = '商城首页'
				}else if(this.PromoteCodeType==2&&this.templateRadio){
					
					this.ruleEditForm.MallShopDecorateId = this.selection.MallShopDecorateId||0
					this.ruleEditForm.PromoteCodeType = this.PromoteCodeType
					this.ruleEditForm.LinkName = this.selection.PageName||''
				} else {
					this.ruleEditForm.MallShopDecorateId  = 0
					this.ruleEditForm.PromoteCodeType = '1'
					this.ruleEditForm.LinkName = ''
				}
				
				this.selectPageVisible = false
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.page.size = val;
				this.getList();
			},
			
			// 翻页
			handleCurrentChange(val) {
				this.page.current = val;
				this.getList();
			},

			
			// 切换显示条数
			handleSelectSizeChange(val) {
				this.selectPage.size = val;
				this.getPageList();
			},
			
			// 翻页
			handleSelectCurrentChange(val) {
				this.selectPage.current = val;
				this.getPageList();
			},
			

		}
	}
</script>

<style lamg="less" scoped>
	.typingRecord {
		padding: 10px;
		background: #fff;
	}
	.content {
		overflow: hidden;
	}
	.link-name-btn{
		color:#409EFF;
		cursor:pointer;
	}

	.link-name-btn:after{
		content: '';
		display: inline-block;
    width: 8px;
    height: 8px;
    border-top: 1px solid #409EFF;
    border-right: 1px solid #409EFF;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
		margin-left:5px;
		margin-bottom:3px;
	}
	.flexRow{
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.rightfont{
		width: 85px;
		text-align: right;
	}
</style>
